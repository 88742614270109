// import * as agCharts from 'ag-charts-community';
import { AgChartsReact } from "ag-charts-react";

function DeviceModelsChart(props) {
  const options = {
    data: props.deviceModels,
    series: [
      {
        type: "pie",
        angleKey: "count",
        labelKey: "model",
      },
    ],
  };
  return (
    <div>
      <h1>Chromebox Models</h1>
      <AgChartsReact options={options} />
    </div>
  );
}

export default DeviceModelsChart;
