export const getGoogleAdminLink = (p) => {
  return (
    <span>
      <a
        href={"https://admin.google.com/ac/chrome/devices/" + p.data.deviceId}
        className="btn-simple button7"
        style={{ color: "#3367d6", fontWeight: "bold" }}
        target="_blank"
        rel="noreferrer"
      >
        View in Google
      </a>
    </span>
  );
};

export const getMajorVersion = (p) => {
  // console.log(parseInt(p.data.osVersion.split(".")[0]));



  if (typeof p.data.osVersion == "undefined") {
    return 0;
  }
  return <span>{parseInt(p.data.osVersion.split(".")[0])}</span>;

};

export const convertToDate = (p) => {
  const lsDate = new Date(p.data.lastSync);
  // console.log(lsDate.getDay())
  if (p.data.lastSync) {
    return (
      <span>
        {lsDate.getMonth() + 1}/{lsDate.getDate()}/{lsDate.getFullYear()}
      </span>
    );
  }
  return <span>null</span>;
};
