// import { googleLogout } from "@react-oauth/google";
import { useState, useEffect, useMemo } from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import {
  getGoogleAdminLink,
  getMajorVersion,
  convertToDate,
} from "./aggrid-custom/helperFunctions.js";
import DeviceModelsChart from "./components/DeviceModelsChart";
import DeviceOSChart from "./components/DeviceOSChart";
import { sampleData } from "./utility/sampleData.js";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Button from "@mui/material/Button";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

// import axios from "axios";
import "./App.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const CLIENT_ID =
  "40784521223-hneolspavdkuni7cdlmfvcqs6htkrm6d.apps.googleusercontent.com";
const SCOPES =
  "https://www.googleapis.com/auth/admin.directory.device.chromeos https://www.googleapis.com/auth/admin.directory.device.chromeos.readonly";
const CUSTOMERID = "C01ktiroj";

const fetchDeviceData = async (access_token, pageToken = undefined) => {
  let data;
  await axios
    .get(
      `https://admin.googleapis.com/admin/directory/v1/customer/${CUSTOMERID}/devices/chromeos/`,
      {
        params: {
          pageToken: pageToken,
          query: "status:provisioned",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then(function (response) {
      data = response.data;
    });
  return data;
};

const filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};

function App() {
  const [user, setUser] = useState({});
  const [tokenClient, setTokenClient] = useState({});
  // const [rowData, setRowData] = useState(sampleData);
  const [rowData, setRowData] = useState([]);
  const [columnDefs] = useState([
    {
      field: "annotatedAssetId",
      headerName: "Asset ID",
      filter: "agTextColumnFilter",
      width: 240,
    },
    {
      field: "status",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      width: 150,
    },
    {
      field: "osVersion",
      headerName: "OS",
      filter: "agNumberColumnFilter",
      width: 140,
      cellRenderer: getMajorVersion,
    },
    {
      field: "macAddress",
      headerName: "MAC",
      filter: "agTextColumnFilter",
      sortable: false,
      width: 140,
    },
    {
      field: "model",
      filter: "agTextColumnFilter",
      width: 260,
    },
    {
      field: "lastSync",
      headerName: "Last Sync",
      filter: "agDateColumnFilter",
      width: 260,
      cellRenderer: convertToDate,
      filterParams: filterParams,
    },
    {
      field: "orgUnitPath",
      filter: "agTextColumnFilter",
      headerName: "OU",
    },
    {
      field: "orgUnitId",
      filter: "agTextColumnFilter",
      headerName: "OU ID",
      sortable: false,
      hide: true,
    },
    {
      field: "annotatedLocation",
      filter: "agTextColumnFilter",
      headerName: "Location",
      sortable: false,
    },
    {
      field: "Google Admin Link",
      width: 160,
      sortable: false,
      filter: false,
      floatingFilter: false,
      cellRenderer: getGoogleAdminLink,
    },
  ]);
  // Chart Variables
  const [deviceModels, setDeviceModels] = useState();
  const [deviceOS, setDeviceOS] = useState();
  const [open, setOpen] = useState(false);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    resizable: true,
    floatingFilter: true,
  }));

  const handleCallbackResponse = (response) => {
    // console.log(response);
    let userObject = jwt_decode(response.credential);
    // console.log(userObject);
    setUser(userObject);
  };

  const getDeviceData = async () => {
    tokenClient.requestAccessToken();
  };

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: CLIENT_ID,
      callback: handleCallbackResponse,
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
    });

    setTokenClient(
      google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        callback: async (tokenResponse) => {
          console.log(tokenResponse);
          setOpen(!open);
          // Now have access to live token
          // return tokenResponse.access_token
          if (tokenResponse && tokenResponse.access_token) {
            let response = await fetchDeviceData(tokenResponse.access_token);
            let fullData = response.chromeosdevices;

            while (response.nextPageToken) {
              console.log(fullData.length);
              response = await fetchDeviceData(
                tokenResponse.access_token,
                response.nextPageToken
              );
              fullData = fullData.concat(response.chromeosdevices);
            }
            setRowData(fullData);
            setOpen(false);
          }
        },
      })
    );
    // tokenClient.requestAccessToken();
  }, []);

  useEffect(() => {
    // Create data structure for charts: [{model: "", count: 1},{}...]
    let models = [];
    let osVersion = [];
    rowData.map((obj) => {
      // Models
      let modelIndex = models.findIndex((item) => {
        return item.model === obj.model;
      });

      if (modelIndex !== -1) {
        models[modelIndex].count++;
      } else {
        models.push({ model: obj.model, count: 1 });
      }
      // OS Versions
      let osIndex = osVersion.findIndex((item) => {
        if (obj.osVersion) {
          return item.osVersion === obj.osVersion.match(/^\d*/g)[0];
        }
        return "undefined";
      });

      if (osIndex !== -1) {
        osVersion[osIndex].count++;
      } else {
        osVersion.push({
          osVersion: obj.osVersion.match(/^\d*/g)[0],
          count: 1,
        });
      }
    });
    setDeviceModels(models);
    setDeviceOS(osVersion);

    // OS Version
  }, [rowData]);

  return (
    <div className="App">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button style={{"margin-top":"5px"}}  variant="outlined" onClick={getDeviceData}>
        Get Data
      </Button>
      <hr style={{"background-color":"#f8f8f8"}}></hr>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid xs={6}>
            {/* <div id="signInDiv"></div> */}
            <Item>
              <DeviceModelsChart deviceModels={deviceModels} />
            </Item>
          </Grid>
          <Grid xs={6}>
            <Item>
              <DeviceOSChart deviceOS={deviceOS} />
            </Item>
          </Grid>
          <Grid xs={12}>
            <Item>
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: 1000 }}
              >
                <AgGridReact
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                ></AgGridReact>
              </div>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default App;
